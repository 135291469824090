import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageSnackbarComponent } from '../components/message-snackbar/message-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService {
  constructor(private _snackBar: MatSnackBar) {}

  openSnackBar(message: string, status?: number) {
    const snackBar = this._snackBar.openFromComponent(
      MessageSnackbarComponent,
      {
        data: { message, status },
        panelClass: ['message-snackbar', '!mt-[72px]'],
      }
    );
  }
}
